<template>
  <!-- 线上订单 -->
  <div class="supplierManagement" v-loading="loading">
    <div class="globle_border content">
      <header>
        <el-select v-model="deliveryStatus" placeholder="请选择订单状态" clearable @change="onCurrentPage">
          <el-option v-for="item in deliveryStatusArr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="payType" placeholder="请选择支付类型" clearable @change="onCurrentPage">
          <el-option v-for="item in paymentType" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input placeholder="请输入订单编号" suffix-icon="el-icon-search" v-model="orderCode" :clearable="true"
          @change="onCurrentPage"></el-input>
        <el-date-picker v-model="formInline.time" @change="gettime" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期"
          style="border-radius: 10px; background: #f2f2f2; margin-left: 20px; border: none"
          value-format="yyyy-MM-dd"></el-date-picker>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
              <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="orderCode" label="订单编号" show-overflow-tooltip min-width="180"
            align="center"></el-table-column>
          <el-table-column prop="shopName" label="店铺名称" show-overflow-tooltip min-width="130"
            align="center"></el-table-column>
          <el-table-column prop="orderAmount" label="销售金额(元)" show-overflow-tooltip min-width="110"
            align="center"></el-table-column>
          <!-- <el-table-column prop="payAmount" label="实收金额(元)" show-overflow-tooltip min-width="110"
            align="center"></el-table-column> -->
          <el-table-column prop="payType" label="支付类型" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div>
                {{ getOrderTypeText(scope.row.payType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="200" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" min-width="100" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.deliveryStatus == 0">待付款</div>
              <div v-if="scope.row.deliveryStatus == 4">已完成</div>
              <div v-if="scope.row.deliveryStatus == 8">已退款</div>
              <div v-if="scope.row.deliveryStatus == 9">已取消</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button v-if="scope.row.aftersaleStatus == 2 && scope.row.deliveryStatus == 4" type="danger"
                size="small" plain @click="handleRefund(scope.row)">退款</el-button>
              <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      startTime: "", //开始时间
      endTime: "", //结束时间
      formInline: {
        time: [],
      }, //日期区间
      // 支付类型
      paymentType: [
        {
          value: "0",
          label: "现金支付",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "1",
          label: "支付宝",
        },
        {
          value: "3",
          label: "会员",
        },
        {
          value: "4",
          label: "银联",
        },
        {
          value: "5",
          label: "其他",
        },
      ],
      // 订单子状态
      deliveryStatusArr: [
        {
          value: "0",
          label: "待付款",
        },
        {
          value: "4",
          label: "已完成",
        },
        {
          value: "8",
          label: "已退款",
        },
        {
          value: "9",
          label: "已取消",
        },
      ],
      payType: "", //支付类型
      deliveryStatus: "", //线下订单状态用线上订单的子状态查询
      orderCode: "", //订单编号
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.orderCode = obj?.orderCode
    this.deliveryStatus = obj?.deliveryStatus
    this.payType = obj?.payType
    this.startTime = obj?.startTime
    this.endTime = obj?.endTime
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productList", {})
    // 获取列表
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true
      this.$axios
        .get(this.$api.ordersList, {
          params: {
            deliveryStatus: this.deliveryStatus || null,//订单子状态
            orderSource: 1, //订单来源：1线下；2线上商城(必填)
            orderCode: this.orderCode || null, //订单编号
            payType: this.payType || null, //支付类型
            shopId: this.$store.state.userInfo.shopId, //登录
            startTime: this.startTime || null, //开始时间
            endTime: this.endTime || null, //结束时间
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.totalCount = result.totalCount
            this.tableData = result.list
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 时间区间搜索
    gettime() {
      this.startTime = this.formInline.time ? this.formInline.time[0] || "" : ""
      this.endTime = this.formInline.time ? this.formInline.time[1] || "" : ""
      this.onCurrentPage()
      // console.log('开始时间:', this.startTime, '结束时间:', this.endTime);
    },
    // 查看按钮
    handleCommand(row) {
      let obj = {
        orderCode: this.orderCode,
        deliveryStatus: this.deliveryStatus,
        payType: this.payType,
        startTime: this.startTime,
        endTime: this.endTime,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)
      this.$router.push({ name: "offlineOrderDet", query: { orderId: row.orderId } }) //线下详情
    },
    // 退款按钮
    handleRefund(row) {
      const { orderId } = row
      const that = this
      this.$confirm("此操作将进行退款操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          that.$axios
            .post(that.$api.refundOffOrders, { orderId })
            .then(res => {
              const { code } = res.data
              if (code == 100) {
                that.$message({ message: "恭喜你，处理退款成功!", type: "success" })
                that.onCurrentPage()
              }
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退款",
          })
        })
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest() //请求数据
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRequest()
    },

    // 订单子状态文字颜色
    getOrderFormStatusColor(status) {
      switch (status) {
        case "1":
          return "red" //待付款
        case "2":
          return "red" //待接单
        case "8":
          return "red" //待退款
        default:
          return ""
      }
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case "0":
          return "现金"
        case "1":
          return "支付宝"
        case "2":
          return "微信"
        case "3":
          return "会员"
        case "4":
          return "银联"
        case "5":
          return "其他"
        default:
          return
      }
    },
  },
}
</script>

<style scoped lang="scss">
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;
    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      /deep/.el-date-editor {
        width: 280px !important;
        margin-left: 10px !important;
        margin-bottom: 20px;
      }

      .el-input,
      .el-select {
        margin-left: 10px;
        width: 180px;
        margin-bottom: 20px;
      }
    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #ebeef5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .el-date-editor,
  .el-range-editor,
  .el-input__inner,
  .el-date-editor--daterange {
    margin-left: 0px !important;
  }

  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 250px;
  }
}
</style>
